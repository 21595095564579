* {
    margin: 0;
    padding: 0;
    border: 0;
    list-style: none;
    text-decoration: none;
    box-sizing: border-box;
}

:root {
    /* Container Variables */
    --container-width-lg: 80%;
    --container-width-md: 90%;
    
    /* Border Radius Variables */
    --border-radius-1: 1.2rem;
    --border-radius-2: 0.8rem;
    --border-radius-3: 0.5rem;
    

    --transition: all 500ms ease;
}

html {
    scroll-behavior: smooth;
}

body {
    font-family: 'Montserrat', sans-serif;
}

.container {
    width: var(--container-width-lg);
    margin-inline: auto;
    max-width: 1920;
}

section {
    padding: 8rem 0rem;
    background-color: #FFFFFF
}

section:nth-child(even) {
    background-color: #F9FAFD;
}

/* Word Styles */
h1 {
    font-size: 48px;
    font-weight: 600;
    line-height: 50px;
    color: #151515;
}

h2 {
    font-weight: 400;
    color: #152a62;
    font-size: 48px;
    line-height: 50px;
}

h3 {
    color: #93420d;
    font-size: 24px;
    font-weight: 300;
    line-height: normal;
    margin-bottom: 30px;
}

h4 {
    color: #333333;
    font-size: 18px;
    font-weight: 600;
    line-height: 65px;
}

p {
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
    color: #656565;
}

.btn {
    background-color: #152a63;
    display: inline-block;
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 0.6rem 2.5rem;
    border: 2px solid transparent;
    cursor: pointer;
    border-radius: 28.95px;
    color: #ffffff;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    transition: var(--transition);
}

.btn:hover {
    background-color: #d9d9d9;
    border-color: #152a63;
    color: #152a63;
    transform: translateY(-0.5rem);
    transition: var(--transition)
}

/* When people are on tablets */
@media screen and (max-width: 1024px) {
    .container {
        width: var(--container-width-md);
        align-items: center;
    }

    h1 {
        font-size: 46px;
    }

    h4 {
        font-size: 15px;
    }
}

@media screen and (max-width: 600px) {
    .container {
        width: var(--container-width-md);
        align-items: center;
    }

    h1 {
        font-size: 36px;
    }

    h2 {
        font-size: 30px;
    }
}